<template>
  <section class="section sect sect__advantages" id="advantages">
    <kinesis-container class="advantages container">
      <div class="programs__header">
        <h2 class="programs__header__title section__title">Преимущества</h2>
      </div>
      <div class="advantages__body">
        <kinesis-element :strength="$mq === 'lg' ? 15 : 0" type="depth" class="advantages__img">
          <LottieComponent json="/public/images/car.json" />
        </kinesis-element>
        <div class="advantages__content">
          <h3 class="advantages__title">Лизинг с нами это:</h3>
          <typical
            v-if="advantages.length"
            :steps="advantages"
            :loop="Infinity"
            wrapper="span"
            class="advantages__subtitle"
          ></typical>
          <mq-layout mq="900+" class="advantages__form">
            <span class="advantages__form__title">Действуй сейчас!</span>
            <form class="advantages__form__inputs" @submit.prevent="submitRequest">
              <label class="advantages__form__input">
                <input type="text" v-model="form.name.value" placeholder="Имя" />
                <small v-if="form.name.message">{{ form.name.message }}</small>
              </label>
              <label class="advantages__form__input">
                <input
                  type="text"
                  v-model="form.phone.value"
                  v-mask="'# (###) ###-##-##'"
                  placeholder="_ (___) ___-__-__"
                />
                <small v-if="form.phone.message">{{ form.phone.message }}</small>
              </label>
              <button class="btn-dark" type="submit">
                <LoadingIndicator v-if="loading" title="Подождите" />
                <span v-else>Отправить</span>
              </button>
            </form>
            <div class="advantages__form__footer">
              <span
                >Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных в
                соответствии с
                <router-link :to="{ name: 'page', params: { link: 'privacy-policy' } }" target="_blank"
                  >Политикой в отношении обработки персональных данных ООО «Лизинг-Трейд»</router-link
                ></span
              >
            </div>
          </mq-layout>
        </div>
        <mq-layout :mq="['768', '560']" class="advantages__form">
          <span class="advantages__form__title">Действуй сейчас!</span>
          <form class="advantages__form__inputs" @submit.prevent="submitRequest">
            <label class="advantages__form__input">
              <input type="text" v-model="form.name.value" placeholder="Имя" />
              <small v-if="form.name.message">{{ form.name.message }}</small>
            </label>
            <label class="advantages__form__input">
              <input
                type="text"
                v-model="form.phone.value"
                v-mask="'# (###) ###-##-##'"
                placeholder="_ (___) ___-__-__"
              />
              <small v-if="form.phone.message">{{ form.phone.message }}</small>
            </label>
            <button class="btn-dark" type="submit">
              <LoadingIndicator v-if="loading" title="Подождите" />
              <span v-else>Отправить</span>
            </button>
          </form>
          <div class="advantages__form__footer">
            <span
              >Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных в
              соответствии с
              <router-link :to="{ name: 'page', params: { link: 'privacy-policy' } }" target="_blank"
                >Политикой в отношении обработки персональных данных ООО «Лизинг-Трейд»</router-link
              ></span
            >
          </div>
        </mq-layout>
      </div>
    </kinesis-container>
  </section>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import typical from "vue-typical";
import LoadingIndicator from "components/LoadingIndicator.vue";
import LottieComponent from "components/Lottie.vue";

export default {
  name: "AdvantagesComponent",
  data() {
    return {
      loading: false,
      form: {
        name: {
          value: "",
          message: "",
        },
        phone: {
          value: "",
          message: "",
        },
        inn: {
          value: " - ",
          message: "",
        },
      },
    };
  },
  computed: {
    advantages() {
      if (
        this.$store.state.home_page &&
        this.$store.state.home_page.advantages &&
        this.$store.state.home_page.advantages.length
      ) {
        return this.$store.state.home_page.advantages
          .map((adv) => adv.advantage)
          .map((adv) => [adv.title, 2000])
          .flat();
      } else {
        return [];
      }
    },
  },
  methods: {
    submitRequest() {
      if (!this.loading) {
        this.loading = true;
        this.resetValidation();
        const fd = new FormData();
        Object.keys(this.form).forEach((key) => {
          fd.append(key, this.form[key].value);
        });
        fd.append("type_id", this.$store.state.type.CODE_CONSULTATION);
        this.$store
          .dispatch("POST_CLAIM", fd)
          .then(({ data }) => {
            this.loading = false;
            if (data.message === "Ok!") {
              if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                  event: "Lead",
                });
              }
              this.$swal("Спасибо! Ваша заявка успешно отправлена.", "Мы свяжемся с Вами в ближайшее время");
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          })
          .catch(({ response }) => {
            this.loading = false;
            if (response.status === 422) {
              // Вывод ошибок валидаций от Laravel
              Object.keys(response.data).forEach((message) => {
                Object.keys(this.form).forEach((field) => {
                  if (field === message) {
                    this.form[field].message = response.data[message][0];
                  }
                });
              });
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          });
      }
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation() {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null;
      });
    },
  },
  components: {
    LottieComponent,
    LoadingIndicator,
    KinesisContainer,
    KinesisElement,
    typical,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.sect__advantages {
  .advantages {
    margin-top 24px
    margin-bottom: 64px
    width 100%
    +below(560px) {
      margin-bottom 0
      margin-top 30px
    }

    &__body {
      display flex
      align-items flex-start
      justify-content flex-start
      margin 0 -15px
      padding-top 30px
      padding-bottom 60px
      box-sizing border-box
      +below(768px) {
        flex-flow row wrap
      }
    }

    &__img {
      flex-basis calc(41.6666667% - (15px * 2))
      margin 0 15px
      height 400px
      +below(768px) {
        flex-basis calc(50% - (15px * 2))
      }
      +below(640px) {
        height 180px
      }
      +below(440px) {
        flex-basis calc(100% - (15px * 2))
        margin-bottom 15px
      }

      img,
      .lottie {
        object-fit cover
        object-position center
        width 100%
        height 350px
        +below(640px) {
          height 100%
        }
      }
    }

    &__content {
      flex-basis calc(58.3333333% - (15px * 2))
      position relative
      z-index 2
      margin 32px 15px 0
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      +below(768px) {
        flex-basis calc(50% - (15px * 2))
      }
      +below(640px) {
        margin-top 0
      }
      +below(440px) {
        flex-basis calc(100% - (15px * 2))
      }
    }

    &__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: main_dark;
      margin 0 0 2px
      width 100%
      +below(640px) {
        font-size: 22px;
        line-height: 27px;
        margin-bottom 11px
      }
    }

    &__subtitle {
      font-family: main_head_decorative;
      font-style: normal;
      font-weight: normal;
      font-size: 54px;
      line-height: 62px;
      color: main_color;
      margin-bottom 40px
      +below(640px) {
        font-size: 34px;
        line-height: 39px;
      }

      &::after {
        content: "|";
        animation: blink 1s infinite step-start;
      }

      @keyframes blink {
        50% {
          opacity: 0;
        }
      }
    }

    &__form {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      +below(768px) {
        width 100%
        margin 15px
      }

      &__title {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: main_dark;
        margin-bottom 15px
      }

      &__inputs {
        display flex
        flex-flow wrap
        align-items flex-start
        justify-content flex-start
        margin -10px

        .btn-dark {
          margin 10px
          +below(640px) {
            width 100%
          }
        }
      }

      &__input {
        display: flex
        flex-direction column
        max-width: 205px;
        margin: 10px;
        box-sizing: border-box;
        +below(640px) {
          max-width: 100%;
          width 100%
        }

        input {
          padding 16px 20px
          width 205px
          height 50px
          border-radius radius
          background: main_white;
          border: 1px solid main_gray;
          +below(640px) {
            width 100%
          }

          &:focus {
            border-color main_dark
          }
        }
      }

      &__footer {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: rgba(main_dark, 0.5);
        margin-top 15px
      }
    }
  }
}
</style>

<template>
  <main class="main">
    <ul class="main-scroll">
      <li v-for="(item, index) in hashes" :key="index" :class="{ active: hash === item.hash }">
        <a
          :href="'#' + item.hash"
          @click.prevent="scrollTo(item.hash)"
          :content="item.title"
          :title="item.title"
          v-tippy="{ followCursor: false, placement: 'left' }"
        ></a>
      </li>
    </ul>
    <WelcomeComponent />
    <ProgramsComponent />
    <BrandTabsComponent />
    <AdvantagesComponent />
  </main>
</template>

<script>
import WelcomeComponent from "@/views/home/components/Welcome.vue";
import ProgramsComponent from "@/views/home/components/Programs.vue";
import AdvantagesComponent from "@/views/home/components/Advantages.vue";
import BrandTabsComponent from "@/views/home/components/BrandTabsComponent.vue";
import { cityIn } from "lvovich";

export default {
  name: "HomePage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_HOME_PAGE");
  },
  data() {
    return {
      hash: "main",
      scrollListener: undefined,
      scrollTimeout: undefined,
      systemScrolling: false,
    };
  },
  computed: {
    hashes() {
      return [
        {
          hash: "main",
          title: "Главная",
        },
        {
          hash: "programs",
          title: "Программы",
        },
        {
          hash: "advantages",
          title: "Преимущества",
        },
      ];
    },
  },
  mounted() {
    if (this.$route.name === "home") {
      if (location.hash) {
        this.systemScrolling = true;
        this.scrollTo(location.hash.substr(1), "instant");
        this.systemScrolling = false;
      } else {
        this.hash = "main";
      }
    }

    /**
     * Если скролл по высоте больше или равно offsetTop самого последнего
     * из хэшей то скролл = offsetTop этого хэша
     */
    this.scrollListener = window.addEventListener("scroll", () => {
      if (!this.systemScrolling) {
        const max = this.getMaxHash();
        this.hash = this.hashes[max].hash;
      }
    });
  },
  methods: {
    scrollTo(hash, behavior = "smooth") {
      window.scrollTo({
        top: document.getElementById(hash).offsetTop,
        behavior: behavior,
      });
      history.pushState({}, null, location.origin + "#" + hash);
      this.hash = hash;
    },
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    getMaxHash() {
      const indexes = [];
      let max = 0;
      this.hashes.forEach((item, i) => {
        indexes.push(i);
        if (document.getElementById(item.hash)) {
          if (window.scrollY >= document.getElementById(item.hash).offsetTop) {
            max = Math.max(...indexes);
          }
        }
      });
      return max;
    },
    calculateScroll() {
      if (this.$route.name === "home") {
        const max = this.getMaxHash();
        if (location.hash !== "#" + this.hashes[max].hash) {
          history.pushState({}, null, location.origin + location.pathname + "#" + this.hashes[max].hash);
          const target = document.getElementById(this.hashes[max].hash);
          if (target) {
            window.scrollTo(0, target.offsetTop);
          }
          this.hash = this.hashes[max].hash;
        }
      } else {
        window.removeEventListener("scroll", () => {});
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let page = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.home_page && this.$store.state.home_page.page) {
      page = this.$store.state.home_page.page;
    }
    const title = (page.meta_title || page.title).replace(/{{ city }}/g, `в ${city}`);
    let img = this.getImg(page) || "";

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: page.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    AdvantagesComponent,
    ProgramsComponent,
    WelcomeComponent,
    BrandTabsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/flickity.styl"
@import "~@/styles/parts/home/index.styl"
</style>

<template>
  <section class="home-tabs container">
    <div class="home-tabs__header">
      <h2>Каталог</h2>
    </div>
    <div class="home-tabs__list">
      <div
        v-for="(title, i) in tabs_title"
        :key="i"
        class="home-tabs__item"
        :class="{ 'home-tabs__item--active': activeTab === i }"
        @click="activeTab = i"
      >
        {{ title }}
      </div>
    </div>
    <div class="home-tabs__sliders">
      <template v-for="(tab, i) in tabs">
        <BrandsSlider :key="tab.id" v-if="activeTab === i" :brands="tab.brands" :vehicle="tab.link" />
      </template>
    </div>
  </section>
</template>

<script>
import BrandsSlider from "components/BrandsSlider.vue";

export default {
  data() {
    return {
      activeTab: 0,
      currentTabs: [],
    };
  },
  mounted() {},
  computed: {
    tabs() {
      return this.$store.state.home_page.vehicles.slice(-3).reverse();
    },
    tabs_title() {
      return this.tabs.map((tab) => tab.title);
    },
  },
  components: {
    BrandsSlider,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.home-tabs {
  .flickity-viewport {

    .brands__name {
      font-size 1.1em !important
      text-align center !important
      margin-bottom 0px !important
    }
  }

  &__header {
    h2 {
      color main_dark;
      font-size 2em
      text-transform uppercase
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(3, 1fr)
    +below(620px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

  &__item {
    border-top 3px solid main_graydark
    padding 30px
    text-align center
    font-size 1.2em
    color main_dark
    transition .3s
    cursor pointer

    +below(1350px) {
      padding 30px 10px
    }

    &--active {
      border-top 4px solid main_color
      text-decoration none
      font-weight 400
      color main_color
    }
  }
}
</style>

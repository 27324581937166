<template>
  <i class="arrow-right-icon icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.92272 1.88L10.0277 8L3.92272 14.12L5.80221 16L13.8 8L5.80221 -4.76837e-07L3.92272 1.88Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "ArrowRightIcon",
};
</script>

<template>
  <i class="list-dot-icon icon">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="7.5" stroke="#FF8000" stroke-width="5"></circle>
    </svg>
  </i>
</template>

<script>
export default {
  name: "ListDotIcon",
};
</script>

<template>
  <i class="arrow-left-icon icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0773 14.12L5.97227 8L12.0773 1.88L10.1978 0L2.19995 8L10.1978 16L12.0773 14.12Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "ArrowLeftIcon",
};
</script>

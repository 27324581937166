<template>
  <section class="section sect sect__welcome" id="main" v-if="slides.length">
    <kinesis-container class="welcome container">
      <div id="flickity_welcome" class="welcome__flickity">
        <div class="welcome__slide" v-for="(slide, index) in slides" :key="index">
          <kinesis-element :strength="$mq === 'lg' ? 35 : 0" type="rotate" class="welcome__img">
            <!--            <LottieComponent json="/public/images/car.json" v-if="slide.icon.substr(-4) === 'json'"/>-->
            <LottieComponent :json="getImg(slide.icon)" v-if="slide.icon.substr(-4) === 'json'" />
            <img loading="lazy" decoding="async" :src="getImg(slide.icon)" :alt="slide.title" v-else />
          </kinesis-element>
          <div class="welcome__content">
            <h2 class="welcome__content__title">{{ slide.title }}</h2>
            <span class="welcome__content__subtitle">{{ slide.subtitle }}</span>
            <ul class="welcome__list" v-if="slide.parameters && slide.parameters.length">
              <li v-for="(item, index) in slide.parameters" :key="index">
                <ListDotIcon />
                <span v-html="item.title"></span>
              </li>
            </ul>
            <div class="welcome__actions">
              <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
              <a :href="slide.button_link" class="btn-dark" v-if="slide.button_link && slide.button_title">{{
                slide.button_title
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="welcome__controls" v-if="slides.length > 1">
        <button class="welcome__controls__button" type="button" title="Налево" @click="flickity.previous()">
          <ArrowLeftIcon />
        </button>
        <ul class="welcome__controls__list">
          <li v-for="(_, index) in slides" :key="index">
            <a
              href="#"
              :title="`Перейти на ${index + 1} слайд`"
              @click.prevent="flickity.select(index + 1)"
              :class="{ active: current === index }"
            ></a>
          </li>
        </ul>
        <button class="welcome__controls__button" type="button" title="Направо" @click="flickity.next()">
          <ArrowRightIcon />
        </button>
      </div>
    </kinesis-container>
  </section>
</template>

<script>
import ListDotIcon from "components/svg/ListDot.vue";
import ArrowLeftIcon from "components/svg/ArrowLeft.vue";
import ArrowRightIcon from "components/svg/ArrowRight.vue";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import LottieComponent from "components/Lottie.vue";

export default {
  name: "WelcomeComponent",
  data() {
    return {
      current: 0,
      flickity: undefined,
    };
  },
  computed: {
    slides() {
      if (
        this.$store.state.home_page &&
        this.$store.state.home_page.slides &&
        this.$store.state.home_page.slides.length
      ) {
        return this.$store.state.home_page.slides;
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (document.getElementById("flickity_programs")) {
      const Flickity = require("flickity");
      this.flickity = new Flickity("#flickity_welcome", {
        prevNextButtons: false,
        pageDots: false,
        adaptiveHeight: true,
      });
      this.flickity.on("change", (index) => {
        this.current = index;
      });
    }
  },
  methods: {
    getImg(link) {
      if (link) {
        return this.$store.state.api + link;
      }
    },
  },
  components: {
    LottieComponent,
    ArrowRightIcon,
    ArrowLeftIcon,
    ListDotIcon,
    KinesisContainer,
    KinesisElement,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/flickity.styl"
@import "~@/styles/parts/home/index.styl"
.welcome__slide
  margin-right 30px

.welcome__flickity
  .flickity-viewport
    transition height 0.2s
</style>

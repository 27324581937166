<template>
  <section class="section sect sect__programs" id="programs" v-if="programs.length">
    <div class="programs container">
      <div class="programs__header section_title__special">
        <h1>Лизинг</h1>
        <h2>
          <router-link :to="{ name: 'programs' }" class="programs__header__title">Программы</router-link>
        </h2>
      </div>
      <div class="programs__body" id="flickity_programs" :class="{ 'flickity-ready': flickityReady }">
        <router-link
          :to="{ name: 'program', params: { url: program.link } }"
          v-for="(program, index) in programs"
          :key="index"
          class="programs__link"
        >
          <kinesis-container class="programs__item">
            <kinesis-element class="programs__item__img" :strength="$mq === 'lg' ? 35 : 0" type="translate">
              <img loading="lazy" decoding="async" :src="getImg(program)" :alt="getImgAlt(program)" />
              <ProgramImgEllipse />
            </kinesis-element>
            <h3 class="programs__item__title">{{ program.button_title || program.title }}</h3>
            <span class="programs__item__subtitle">{{ program.subtitle }}</span>
            <button type="button" class="btn-white">Подробнее</button>
          </kinesis-container>
        </router-link>
      </div>
      <div class="programs__controls">
        <button class="welcome__controls__button" type="button" @click="flickity.previous()" v-if="slides">
          <ArrowLeftIcon />
        </button>
        <ul class="welcome__controls__list" v-if="slides">
          <li v-for="(_, index) in slides" :key="index">
            <a
              href="#"
              :title="`Перейти на ${index + 1} слайд`"
              @click.prevent="flickity.select(index + 1)"
              :class="{ active: current === index }"
            ></a>
          </li>
        </ul>
        <button class="welcome__controls__button" type="button" @click="flickity.next()" v-if="slides">
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import ArrowLeftIcon from "components/svg/ArrowLeft.vue";
import ArrowRightIcon from "components/svg/ArrowRight.vue";
import ProgramImgEllipse from "components/svg/ProgramImgEllipse.vue";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

export default {
  name: "ProgramsComponent",
  data() {
    return {
      current: 0,
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    programs() {
      if (
        this.$store.state.home_page &&
        this.$store.state.home_page.programs &&
        this.$store.state.home_page.programs.length
      ) {
        return this.$store.state.home_page.programs
          .filter((p) => p.type.code === this.$store.state.type.CODE_PROGRAM_MAIN)
          .sort((a, b) => {
            return (a.position || 0) - (b.position || 0);
          });
      } else {
        return [];
      }
    },
    slides() {
      if (this.programs.length) {
        let length = this.programs.length;
        if (this.$mq === "560" || this.$mq === "768" || this.$mq === "900") {
          length = this.programs.length;
        } else if (this.$mq === "1097") {
          length = Math.ceil(this.programs.length / 2);
        } else if (this.$mq === "lg") {
          length = Math.ceil(this.programs.length / 3);
        }
        return length === 1 ? 0 : length;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    if (document.getElementById("flickity_programs")) {
      const Flickity = require("flickity");
      let cellsGroup = 3;
      if (this.$mq === "560" || this.$mq === "768" || this.$mq === "900") {
        cellsGroup = false;
      } else if (this.$mq === "1097") {
        cellsGroup = 2;
      } else if (this.$mq === "lg") {
        cellsGroup = 3;
      }
      this.flickity = new Flickity("#flickity_programs", {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        contain: true,
        groupCells: cellsGroup,
        on: {
          change: (index) => {
            this.current = index;
          },
          ready: () => {
            this.flickityReady = true;
          },
        },
      });
    }
  },
  methods: {
    getImg(program) {
      if (program.head_img && program.head_img.img.url) {
        return this.$store.state.api + program.head_img.img.url;
      }
    },
    getImgAlt(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.alt) {
        return item.head_img.img.alt;
      }
    },
  },
  components: {
    KinesisContainer,
    KinesisElement,
    ProgramImgEllipse,
    ArrowRightIcon,
    ArrowLeftIcon,
  },
};
</script>

<template>
  <i class="program-img-ellipse-icon icon">
    <svg width="150" height="60" viewBox="0 0 150 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="75" cy="30" rx="75" ry="30" fill="#DAE3EA"></ellipse>
    </svg>
  </i>
</template>

<script>
export default {
  name: "ProgramImgEllipse",
};
</script>
